<template>
    <div>
        <div class="container header-fixed">
            <div class="row justify-content-center">
                <div class="col-md-10">
                    <div class="d-block pt-lg-5 pt-md-5 pt-3">
                        <h1>Perolehan Formulir</h1>
                    </div>
                    <!-- Search -->
                    <div class="d-flex align-items-center mb-0 d-flex-mobile">
                        <div class="input-group input-group-merge mb-md-0 mb-3 flex-grow-1">
                            <input v-model="keyword" type="search" class="form-control form-control-prepended search"
                                placeholder="Cari formulir disini..." v-on:keyup.enter="getData">
                            <div class="input-group-prepend">
                                <div class="input-group-text">
                                    <span class="fe fe-search"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Formulir List Front -->
                    <div class="section pb-3">
                        <div class="d-flex align-items-center mb-3 pb-3 border-bottom">
                            <a href="javascript:history.back()" class="mr-md-4 mr-3"><span class="fe fe-arrow-left mr-1"></span>Kembali</a>
                            <h4 class="m-0">Menampilkan {{listData.length}} formulir tersedia</h4>
                        </div>

                        <div v-if="fetching" class="row">
                            <div v-for="(item,index) in [1,2,3,4,5,6,7,8]" :key="index" class="col-md-3 mb-4 col-load">
                                <div class="card h-100 m-0">
                                    <div class="d-block p-3 h-100">
                                        <div class="d-flex flex-column justify-content-between h-100">
                                            <div class="flex-grow-1">
                                                <div class="card-img-news mb-3 add-skeleton-load delay-1"></div>
                                                <div class="skeleton-bar">
                                                    <div class="sb-lg mb-2"></div>
                                                </div>
                                            </div>
                                            <div>
                                                <div class="skeleton-bar">
                                                    <div class="sb-lg sb-bold"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div v-if="!fetching">
                            <div v-if="listData.length == 0"  class="empty-content" data-empty-title="Daftar formulir tidak tersedia">
                                <div class="row hide-mobile">
                                    <div v-for="(item,index) in [1,2,3,4]" :key="index" class="col-md-3 mb-4 col-load">
                                        <div class="card h-100 m-0">
                                            <div class="d-block p-3 h-100">
                                                <div class="d-flex flex-column justify-content-between h-100">
                                                    <div class="flex-grow-1">
                                                        <div
                                                            class="card-img-news mb-3 add-skeleton-load skeleton-empty delay-1">
                                                        </div>
                                                        <div class="skeleton-bar skeleton-empty">
                                                            <div class="sb-lg mb-2"></div>
                                                            <div class="sb-md mb-2"></div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div class="skeleton-bar skeleton-empty">
                                                            <div class="sb-lg sb-bold"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row show-mobile">
                                    <div v-for="(item,index) in [1,2]" :key="index" class="col-md-3 mb-4 col-load">
                                        <div class="card h-100 m-0">
                                            <div class="d-block p-3 h-100">
                                                <div class="d-flex flex-column justify-content-between h-100">
                                                    <div class="flex-grow-1">
                                                        <div
                                                            class="card-img-news mb-3 add-skeleton-load skeleton-empty delay-1">
                                                        </div>
                                                        <div class="skeleton-bar skeleton-empty">
                                                            <div class="sb-lg mb-2"></div>
                                                            <div class="sb-md mb-2"></div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div class="skeleton-bar skeleton-empty">
                                                            <div class="sb-lg sb-bold"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div v-if="listData.length > 0" class="row row-sm">
                                <div v-for="(item, index) in listData" :key="index" class="col-md-3 mb-4">
                                    <div class="card lift h-100 m-0">
                                        <div class="d-block h-100">
                                            <div class="d-flex flex-column h-100">
                                                <div class="flex-grow-1">
                                                    <div class="card-img-top"
                                                        v-bind:style="{ 'background-image': 'url(' + item.cover + ')' }">
                                                    </div>
                                                    <div class="d-block p-3">
                                                        <div class="d-none small text-primary mb-1">
                                                            {{item.text_biaya_formulir}}</div>
                                                        <div class="mb-1 mt-0 text-sm">{{item.unit}}</div>
                                                        <h3 class="mb-1 mt-0">{{item.nama_formulir}}</h3>
                                                        <div class="d-block px-3 py-2 bg-light rounded text-sm mb-2">
                                                            {{item.keterangan}}
                                                        </div>
                                                        <div class="mb-0 text-sm">
                                                            {{item.nama_gelombang}}
                                                        </div>
                                                        <div class="text-muted mt-1 text-sm">{{item.nama_periode}}</div>
                                                    </div>
                                                </div>
                                                <div v-if="!userdata.status" class="d-block p-3">
                                                    <router-link to="/sign-in" class="btn btn-primary btn-block">Submit
                                                    </router-link>
                                                </div>

                                                <div v-if="userdata.status" class="d-block p-3">
                                                    <div v-if="userdata.role != 'siswa'">
                                                        <router-link to="/sign-in" class="btn btn-primary btn-block">
                                                            Submit
                                                        </router-link>
                                                    </div>
                                                    <div v-if="userdata.role == 'siswa'">
                                                        <button type="button" class="btn btn-primary btn-block"
                                                            v-on:click="submitFormulir(item.id)">Submit</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div class="d-block mb-5">
                                <div class="d-block font-weight-bold pb-3 border-bottom mt-5 mb-2">
                                Unit lainnya
                                </div>

                                <VueSlickCarousel :arrows="true" :dots="true" v-bind="settings" class="ml-md-n3 mr-md-n3 ml-n2 mr-n2">
                                    <div v-for="(item, index) in relatedData" :key="index" class="d-block p-md-3 p-2">
                                        <div class="card h-100 m-0">
                                            <div class="d-block h-100">
                                                <div class="d-flex flex-column h-100">
                                                    <div class="mb-4 flex-grow-1">
                                                        <div class="card-img-top" v-bind:style="{ 'background-image': 'url(' + item.cover + ')' }">
                                                        </div>
                                                        <div class="d-block p-3">
                                                            <h3 class="mb-1 mt-0">{{item.title}}</h3>
                                                            <div class="text-muted lh-sm lh-sm">
                                                                {{item.description}}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="d-block p-3">
                                                        <button type="button" v-on:click="redirectPage(item.id)" class="btn btn-block btn-primary-light">Lihat</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </VueSlickCarousel>
                            </div>


                            <!--div class="d-none text-center py-4">
                                <button class="btn btn-primary-light px-4">Muat Lebih Banyak</button>
                            </div-->
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    // @ is an alias to /src
    import {
        mapState
    } from 'vuex'
    // Slck
    import VueSlickCarousel from 'vue-slick-carousel'
    
    export default {
        name: 'Beranda',
        components: {
            VueSlickCarousel,
        },
        metaInfo: {
            title: 'SchoolPro',
            titleTemplate: '%s - PPDB'
        },
        mounted() {
            this.$store.dispatch('loadUserData')
        },
        computed: mapState([
            'userdata'
        ]),
        data() {
            return {
                search: "",
                listData: [],
                pageOfItems: [],
                relatedData: [],
                fetching: true,
                // VueSlick Settngs
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    dots: true,
                    infinite: false,
                    responsive: [
                        {
                            breakpoint: 767,
                            settings: {
                                slidesToShow: 2,
                                slidesToScroll: 1,
                                arrows: false,
                                dots: true,
                                autoplay: true,
                                infinite: true
                            }
                        }
                    ]

                },
                keyword : ''
            }
        },
        created() {
            this.getData();
        },
        methods: {
            getData: function () {
                this.listData = [];
                this.fetching = true;
                let uri = this.$apiconfig + 'website/formulir/list';
                this.$http.get(uri, {
                    params: {
                        unit_id: this.$route.params.id,
                        keyword : this.keyword
                    }
                }).then(res => {
                    let getResponse = res.data;
                    if (getResponse.status) {
                        this.listData = getResponse.data;
                    } else {
                        this.listData = [];
                    }
                    this.relatedData = getResponse.list_related_data;
                    this.fetching = false;
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                });
            },
            submitFormulir: function (params) {
                this.$http.post(this.$apiconfig + 'website/registrasi/submit', {
                        id: params,
                    })
                    .then(response => {
                        if (response.data.status) {
                            this.$swal({
                                icon: 'success',
                                title: 'Berhasil',
                                text: response.data.message,
                                confirmButtonText: 'OK',
                                showCancelButton: false,
                            }).then((result) => {
                                if (result.value) {
                                    this.$router.push('/calon-siswa/formulir');
                                }
                            });
                        } else {
                            this.$swal({
                                icon: 'error',
                                title: 'Oops...',
                                text: response.data.message,
                            })
                        }
                    })
                    .catch(function (error) {
                        // Swal.fire('Uh oh!', 'Please check your internet connection.', 'error')
                        console.debug("Login issue: " + error)
                    });
            },
            redirectPage: function(page_id) {
                this.$router.push({ name: 'list_formulir', params: { id: page_id }});
                this.getData();
            }
        }
    }
</script>